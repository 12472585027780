<!--
 * @Description: 
 * @FilePath: /annianjian-official-website/src/pages/about.vue
 * @Date: 2022-09-27 10:33:23
 * @Author: Agan
 * @LastEditTime: 2022-09-27 11:00:32
-->
<template>
  <div>
    <div class="about">
      <img src="../assets/banner1.jpg" alt="banner1" />
      <div class="introduce flex-a">
        <ul class="desc">
          <li>
            <p>
              广州百团科技有限公司旗下品牌安年检成立于2020年，通过移动互联技术改善传统车检代办服务的行业引领者。目前，安年检小程序可以发起代车检的需求服务。通过行业独有的“派单制”遴选距离用户最近的送检员，为您提供代车检服务。
            </p>
          </li>
          <li>
            <p>安年检有以下服务优势:</p>
            <p>
              1.设立了严格的送检员入职制度，所有送检员必须满足近3年安全驾驶，而且必须通过笔试、面试、培训、路考等严格的测试，才能成为送检员。
            </p>
            <p>
              2.目前有20万名专业送检员，在北京、上海、广州、深圳等298座城市开通了代年检服务，在国内的主要城市，都能享受到安年检的代车检服务。
            </p>
            <p>
              3.设立高额代车检保险，一旦因为送检员的原因出现交通事故，您的损失将得到保障。
            </p>
          </li>
          <li>
            <p>
              代车检是一种托付，更是一种信任，安年检致力于为每一位车主提供更优质、更安全的代车检服务，让车主在省时省心的情况下把爱车给检验了，让更多的车主体会科技升级带来的便捷生活。
            </p>
          </li>
        </ul>
        <img src="../assets/about.jpg" alt="关于我们" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .about {
    width: 1200px;
    margin: 0 auto;
  }
}
.about {
  height: 750px;
  position: relative;
  img {
    width: 100%;
  }
  .introduce {
    width: 1106px;
    height: 480px;
    padding: 60px 40px;
    border-radius: 10px;
    background: #f9fcff;
    position: absolute;
    top: 270px;
    margin-left: 50%;
    transform: translateX(-50%);
    .desc {
      li {
        margin-bottom: 36px;
        p {
          font-size: 12px;
          text-indent: 2em;
          line-height: 26px;
        }
      }
    }
    img {
      width: 350px;
      height: 374px;
      margin-left: 30px;
    }
  }
}
</style>
